import React, { useState } from 'react';


import { getImage } from "gatsby-plugin-image"
import {  graphql,useStaticQuery  } from 'gatsby';
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { BgImage } from "gbimage-bridge"

import Layout from "../components/layout"
import SEO from "../components/seo"


import BedIcon from "../images/svg/bed-icon.svg";
import ViewIcon from "../images/svg/view-icon.svg";
import BathIcon from "../images/svg/bath-icon.svg";
import BalconyIcon from "../images/svg/balcony-icon.svg";
import FoodIcon from "../images/svg/food-icon.svg";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs, Navigation, Pagination } from 'swiper/core';


SwiperCore.use([Thumbs, Navigation, Pagination]);


  


const RoomsPage = () => {

  const [thumbsSwiper1, setThumbsSwiper1] = useState(null);
  const [thumbsSwiper2, setThumbsSwiper2] = useState(null);
  const [thumbsSwiper3, setThumbsSwiper3] = useState(null);

  

    const {
        titleStrip,
        roomOne1,
        roomOne2,
        roomOne3,
        roomOne4,
        roomOne5,

        roomTwo1,
        roomTwo2,
        roomTwo3,
        roomTwo4,

        roomThree1,
        roomThree2,
        roomThree3,
        roomThree4,
        
      } = useStaticQuery( graphql`
      query {

        titleStrip: file(relativePath: { eq: "title-banner.jpg" }) {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
            }
        }

        roomOne1: file(relativePath: { eq: "roomOne/roomOne1.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }

        roomOne2: file(relativePath: { eq: "roomOne/roomOne2.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }

        roomOne3: file(relativePath: { eq: "roomOne/roomOne3.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }

        roomOne4: file(relativePath: { eq: "roomOne/roomOne4.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }

        roomOne5: file(relativePath: { eq: "roomOne/roomOne5.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }

        roomTwo1: file(relativePath: { eq: "roomTwo/roomTwo1.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }

        roomTwo2: file(relativePath: { eq: "roomTwo/roomTwo2.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }

        roomTwo3: file(relativePath: { eq: "roomTwo/roomTwo3.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }

        roomTwo4: file(relativePath: { eq: "roomTwo/roomTwo5.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }

        roomThree1: file(relativePath: { eq: "roomThree/roomThree1.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }

        roomThree2: file(relativePath: { eq: "roomThree/roomThree2.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }

        roomThree3: file(relativePath: { eq: "roomThree/roomThree3.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }

        roomThree4: file(relativePath: { eq: "roomThree/roomThree4.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
    
    
      }
    `)


    const titleStripBg = convertToBgImage(getImage(titleStrip))

    const roomOne1Bg = getImage(roomOne1)
    const roomOne2Bg = getImage(roomOne2)
    const roomOne3Bg = getImage(roomOne3)
    const roomOne4Bg = getImage(roomOne4)
    const roomOne5Bg = getImage(roomOne5)

    const roomTwo1Bg = getImage(roomTwo1)
    const roomTwo2Bg = getImage(roomTwo2)
    const roomTwo3Bg = getImage(roomTwo3)
    const roomTwo4Bg = getImage(roomTwo4)

    const roomThree1Bg = getImage(roomThree1)
    const roomThree2Bg = getImage(roomThree2)
    const roomThree3Bg = getImage(roomThree3)
    const roomThree4Bg = getImage(roomThree4)

 
{/* <BgImage className="swiper-slide" image={room1Bg} ></BgImage> */}

      return (
        <Layout>
          <SEO title="Rooms" description="Located near Gansbaai beach, great for whale watching" />
          <BackgroundImage
            className="container-fluid title-banner"
            {...titleStripBg}
          >
            <h2 className="section-heading">Rooms</h2>
          </BackgroundImage>

          <div className="container rooms-page-con">
            <div className="row room-item">
              <div className="col-md-7">
                <main>
                  {/* Main Swiper -> pass thumbs swiper instance */}
                  <Swiper 
                    thumbs={{ swiper: thumbsSwiper1 }}
                    navigation
                    pagination={{ clickable: true }}

                  >
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomOne1Bg} ></BgImage>
                    </SwiperSlide>
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomOne2Bg} ></BgImage>
                    </SwiperSlide>
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomOne3Bg} ></BgImage>
                    </SwiperSlide>
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomOne4Bg} ></BgImage>
                    </SwiperSlide>
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomOne5Bg} ></BgImage>
                    </SwiperSlide>
                  </Swiper>

                  {/* Thumbs Swiper -> store swiper instance */}
                  {/* It is also required to set watchSlidesVisibility and watchSlidesProgress props */ }
                  <Swiper
                    onSwiper={setThumbsSwiper1}
                    watchSlidesVisibility
                    watchSlidesProgress
                    spaceBetween={20}
                    slidesPerView={4}
                    className="thumb-slider"
                  >
                    <SwiperSlide>
                          <BgImage className="bg-com-slide-thumb"  image={roomOne1Bg} ></BgImage>
                      </SwiperSlide>
                      <SwiperSlide>
                          <BgImage className="bg-com-slide-thumb"  image={roomOne2Bg} ></BgImage>
                      </SwiperSlide>
                      <SwiperSlide>
                          <BgImage className="bg-com-slide-thumb"  image={roomOne3Bg} ></BgImage>
                      </SwiperSlide>
                      <SwiperSlide>
                          <BgImage className="bg-com-slide-thumb"  image={roomOne4Bg} ></BgImage>
                      </SwiperSlide>
                      <SwiperSlide>
                          <BgImage className="bg-com-slide-thumb"  image={roomOne5Bg} ></BgImage>
                      </SwiperSlide>
                  </Swiper>
                </main>


              </div>
              <div className="col-md-5">
                <div className="about-info">
                  <h2>Suite Room</h2>
                  <p>
                  The suite consists of a king size bed, sea facing room, balcony and seating area. 
                  Desk, tea and coffee station, bar fridge and hairdryer. En-suite bath and shower. 
                  </p>
                  <div className="featured-info-icons room-icons">
                    <span>
                      <BedIcon className="bed-icon" />
                      King size bed
                    </span>
                    <span>
                      <ViewIcon className="view-icon" />
                      Sea view
                    </span>

                    <span>
                      <BalconyIcon className="view-icon" />
                      Balcony
                    </span>

                    <span>
                      <BathIcon className="bath-icon" />
                      En-suite bath and shower
                    </span>
                    <span>
                      <FoodIcon className="food-icon" />
                      Breakfast and dinner served
                    </span>
                  </div>
                  <div className="bottom-info">
                    <span>
                      <sup>R</sup>
                      3000
                      <sub>night</sub>
                    </span>
                    <a className="default-button"
                      href="http://www.nightsbridge.co.za/bridge/book?bbid=21145"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Book
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row room-item">
              <div className="col-md-7">
                <main>
                  {/* Main Swiper -> pass thumbs swiper instance */}
                  <Swiper 
                    thumbs={{ swiper: thumbsSwiper2 }}
                    navigation
                    pagination={{ clickable: true }}

                  >
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomTwo1Bg} ></BgImage>
                    </SwiperSlide>
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomTwo2Bg} ></BgImage>
                    </SwiperSlide>
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomTwo3Bg} ></BgImage>
                    </SwiperSlide>
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomTwo4Bg} ></BgImage>
                    </SwiperSlide>
                  </Swiper>

                  {/* Thumbs Swiper -> store swiper instance */}
                  {/* It is also required to set watchSlidesVisibility and watchSlidesProgress props */ }
                  <Swiper
                    onSwiper={setThumbsSwiper2}
                    watchSlidesVisibility
                    watchSlidesProgress
                    spaceBetween={20}
                    slidesPerView={4}
                    className="thumb-slider"
                  >
                    <SwiperSlide>
                          <BgImage className="bg-com-slide-thumb"  image={roomTwo1Bg} ></BgImage>
                      </SwiperSlide>
                      <SwiperSlide>
                          <BgImage className="bg-com-slide-thumb"  image={roomTwo2Bg} ></BgImage>
                      </SwiperSlide>
                      <SwiperSlide>
                          <BgImage className="bg-com-slide-thumb"  image={roomTwo3Bg} ></BgImage>
                      </SwiperSlide>
                      <SwiperSlide>
                          <BgImage className="bg-com-slide-thumb"  image={roomTwo4Bg} ></BgImage>
                      </SwiperSlide>
                  </Swiper>
                </main>


              </div>
              <div className="col-md-5">
                <div className="about-info">
                  <h2>Queen Room Full En-suite</h2>
                  <p>
                  The room has a queen size bed, sea facing room. Hair dryer, 
                  tea and coffee station, bar fridge and desk. Seating area, en-suite shower and bath. 
                  </p>
                  <div className="featured-info-icons room-icons">
                    <span>
                      <BedIcon className="bed-icon" />
                      Queen size bed
                    </span>
                    <span>
                      <ViewIcon className="view-icon" />
                      Sea view
                    </span>
                    <span>
                      <BathIcon className="bath-icon" />
                      En-suite bath and shower
                    </span>
                    <span>
                      <FoodIcon className="food-icon" />
                      Breakfast and dinner served
                    </span>
                  </div>
                  <div className="bottom-info">
                    <span>
                      <sup>R</sup>
                      2800
                      <sub>night</sub>
                    </span>
                    <a className="default-button"
                      href="http://www.nightsbridge.co.za/bridge/book?bbid=21145"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Book
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row room-item">
              <div className="col-md-7">
                <main>
                  {/* Main Swiper -> pass thumbs swiper instance */}
                  <Swiper 
                    thumbs={{ swiper: thumbsSwiper3 }}
                    navigation
                    pagination={{ clickable: true }}

                  >
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomThree1Bg} ></BgImage>
                    </SwiperSlide>
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomThree2Bg} ></BgImage>
                    </SwiperSlide>
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomThree3Bg} ></BgImage>
                    </SwiperSlide>
                    <SwiperSlide>
                        <BgImage className="bg-com-slide"  image={roomThree4Bg} ></BgImage>
                    </SwiperSlide>
                  </Swiper>

                  {/* Thumbs Swiper -> store swiper instance */}
                  {/* It is also required to set watchSlidesVisibility and watchSlidesProgress props */ }
                  <Swiper
                    onSwiper={setThumbsSwiper3}
                    watchSlidesVisibility
                    watchSlidesProgress
                    spaceBetween={20}
                    slidesPerView={4}
                    className="thumb-slider"
                  >
                    <SwiperSlide>
                        <BgImage className="bg-com-slide-thumb"  image={roomThree1Bg} ></BgImage>
                      </SwiperSlide>
                      <SwiperSlide>
                        <BgImage className="bg-com-slide-thumb"  image={roomThree2Bg} ></BgImage>
                      </SwiperSlide>
                      <SwiperSlide>
                        <BgImage className="bg-com-slide-thumb"  image={roomThree3Bg} ></BgImage>
                      </SwiperSlide>
                      <SwiperSlide>
                        <BgImage className="bg-com-slide-thumb"  image={roomThree4Bg} ></BgImage>
                      </SwiperSlide>
                  </Swiper>
                </main>


              </div>
              <div className="col-md-5">
                <div className="about-info">
                  <h2>Queen Room En-Suite Shower Only</h2>
                  <p>
                  The suite consists of a king size bed, sea facing room, balcony and seating area. 
                  Desk, tea and coffee station, bar fridge and hairdryer. En-suite bath and shower. 
                  </p>
                  <div className="featured-info-icons room-icons">
                    <span>
                      <BedIcon className="bed-icon" />
                      Queen size bed
                    </span>
                    <span>
                      <ViewIcon className="view-icon" />
                      Sea view
                    </span>

                    <span>
                      <BalconyIcon className="view-icon" />
                      Balcony
                    </span>
                    <span>
                      <BathIcon className="bath-icon" />
                      En-suite shower
                    </span>
                    <span>
                      <FoodIcon className="food-icon" />
                      Breakfast and dinner served
                    </span>
                  </div>
                  <div className="bottom-info">
                    <span>
                      <sup>R</sup>
                      2800
                      <sub>night</sub>
                    </span>
                    <a className="default-button"
                      href="http://www.nightsbridge.co.za/bridge/book?bbid=21145"
                      target="_blank"
                      rel="noreferrer"
                      
                    >
                      Book
                    </a>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </Layout>
      )
  
}



export default RoomsPage